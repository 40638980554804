.helpText {
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  font-size: 12px;
  color: rgb(3, 179, 225);
  display: block;
  margin-top: 5px;
}
.container {
  display: flex;
  flex-wrap: wrap;
}
.input {
  max-width: 800px !important;
  width: auto !important;
}
