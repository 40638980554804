.helpText {
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  font-size: 12px;
  color: rgb(3, 179, 225);
  display: block;
  margin-top: 5px;
}

.radio {
  color: rgb(244, 67, 54) !important;
}
.red {
  color: rgb(244, 67, 54) !important;
}

.checked {
}
